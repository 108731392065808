import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import React, {ReactElement} from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default function BlogPage(): ReactElement {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: {fields: [frontmatter___path], order: DESC}
        filter: {frontmatter: {draft: {ne: true}}}
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              updated
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 250)
                }
              }
            }
            html
            excerpt
          }
        }
      }
    }
  `);

  const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
  return (
    <Layout>
      <SEO title="Blog" />

      {data.allMarkdownRemark.edges.map(({node}) => (
        <div key={node.frontmatter.path} className="card mb-3">
          <div className="row no-gutters" style={{height: '250px'}}>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">
                  <Link className="link-primary" to={node.frontmatter.path}>
                    {node.frontmatter.title}
                  </Link>
                </h5>
                <p className="card-text">{node.excerpt}</p>
                <p className="card-text">
                  <small className="text-muted">
                    Last updated{' '}
                    {new Date(node.frontmatter.updated.split('-')).toLocaleDateString(
                      'en-US',
                      dateOptions
                    )}
                  </small>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <Link to={node.frontmatter.path}>
                <GatsbyImage
                  image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                  className="card-img"
                  style={{height: '250px'}}
                  alt="Blog post image"
                />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </Layout>
  );
}
